import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const Features = ({location}) => (
  <DefaultLayout
    title="Features For Game Dev Project Management"
    description="Ever lost track of a comment thread? Conversations in Codecks are very explicit about who is part and whether it's been resolved."
    location={location}
  >
    <Hero size="sm" title="Conversations & Notifications" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="conversations-1.png">
        Codecks saves you from getting lost in too many sprawling conversations by embedding each
        conversation within a thread belonging to a card. Each thread has its own list of
        participants such that everyone knows where his or her feedback is needed.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Signal important conversations by modifying a card's state"
        img="conversations-2.png"
        imgRight
      >
        Conversation threads come in three flavours. <b>reviews</b> to ask for feedback,
        <b>block</b> a card to indiciate to others that you can't progress without further input and
        simple <b>comment threads</b> for in between communication.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Always know who's involved"
        img="conversations-3.png"
      >
        Add participants by mentioning them. All particpants will receive a persistent notification
        that disappears after replying or actively dismissing it. Participants can opt out if they
        feel they can't contribute no more.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Resolve and move on"
        img="conversations-4.png"
        imgRight
      >
        Resolve conversations to let everyone know that you arrived at a conclusion. This will
        greatly help your team stay focussed on threads that have not been resolved yet.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Notifications">
      <ThemedSingleFeature childAsDescription img="notifications-1.png">
        Our notifications are actually not inspired by other apps - but by games such as
        Civilization 5 and Endless Legend. This implies that we're aiming for a system similar to
        inbox zero.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Keep what's important, dismiss the rest."
        img="notifications-2.png"
        imgRight
      >
        Every notification will stay visible until you've made the conscious decision to act on
        them. This way you're much less likely to accidentally drop important information. Once
        you're done, you dismiss it with a click.
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription title="Context matters" img="notifications-3.png">
        We keep notifications meaningful to you – sending you notifications only for changes that
        affect your cards, or decks that you are subscribed to.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="For cards and conversations"
        img="notifications-4.png"
        imgRight
      >
        We don't just use notifications for card changes but also to help you keep track of
        conversations. You will always be notified of conversations that require your input.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Get notified beyond Codecks"
        img="notifications-5.png"
      >
        Our Slack and Discord integrations allow you to set up what changes will notify you and your
        team in your favorite chat tools.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
